import type { InitialsCircleProps } from '@shared-snap/snap/registry/ui-elements-registry'

export function InitialsCircle({ initials, size, backgroundColor = 'snap-red' }: InitialsCircleProps) {
    const sizeClass = size === 'sm' ? 'w-7 h-7' : size === 'lg' ? 'w-12 h-12' : 'w-8 h-8'
    const textSizeClass = size === 'sm' ? 'text-xs' : size === 'lg' ? 'text-lg' : 'text-xs'

    return (
        <div className={`flex items-center justify-center ${sizeClass} bg-${backgroundColor} rounded-full`}>
            <span className={`text-white ${textSizeClass}`}>{initials}</span>
        </div>
    )
}
