import type { FirebaseFirestore } from '../firebase'
import type { SubscriptionStruct } from '../firestore-structs'

export async function getOrgSubscription(db: FirebaseFirestore, orgKey: string) {
    const subscriptionRef = await db
        .collection('organizations')
        .doc(orgKey)
        .collection('subscriptions')
        .where('status', 'in', ['active', 'trial'])
        .orderBy('created', 'asc')
        .limitToLast(1)
        .get()

    return subscriptionRef.docs[0].data() as SubscriptionStruct
}
