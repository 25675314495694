import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCurrentOrg, useCurrentSubscription, useCurrentUser, useSidebar } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { useSignOut } from '@shared-snap/snap/components/auth/hooks/use-sign-out'
import { taskboardContextAtom } from '@shared-snap/snap/components/my-tasks/state/my-tasks'
import { useChangeOrganization } from '@shared-snap/snap/components/settings/hooks/use-change-organization'
import { useFeatureToggle } from '@shared-snap/snap/hooks/use-feature-toggles'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { Icon } from '@shared-snap/snap/types/icon-font.enum'
import { type Colors, snapColors } from '@shared-snap/snap/types/visuals.types'
import { getNavBarAuthLogo } from '@shared/brand'
import * as colors from '@shared/colors'
import Logo from '@shared/img/brand/sweeply/icon.svg'
import ApaleoIcon from '@shared/img/integration/apaleo-logo.svg'
import ActionIcon from '@shared/img/integration/go-to-link.svg'
import { TaskboardContext } from '@shared/traces-types'
import { Link, useRouter, useRouterState } from '@tanstack/react-router'
import firebase, { asFirebase, auth } from 'app/firebase'
import ContextMenu from 'components/atoms/context-menu/context-menu'
import { InitialsCircle } from 'components/atoms/initials-circle/initials-circle'
import Tooltip from 'components/atoms/tooltip'
import { LanguageSwitcher } from 'components/molecules/language-switcher'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useRecoilValue } from 'recoil'
import { prefix } from 'routes/__root'
import { renderIcon } from './account/subscription'

function useHoveredItem(isActive?: boolean) {
    const [isHovered, setIsHovered] = useState(false)
    const defaultColor = isActive ? 'snap-red' : 'snap-mid-gray'
    const hoverColor = 'snap-black'
    const hoveredColor: Colors = !isActive && isHovered ? hoverColor : defaultColor

    return {
        isHovered,
        hoveredColor,
        setIsHovered
    }
}

export function Aside() {
    const [isSidebarOpen] = useSidebar()

    return (
        <div
            style={{
                backgroundColor: colors.milk_white,
                borderColor: colors.milk_gray
            }}
            className={`fixed flex flex-col justify-between duration-300 ease-linear overflow-auto border-r pt-8 pb-8 h-screen group z-50 ${
                isSidebarOpen ? 'w-[248px]' : 'w-[80px]'
            }`}>
            <div>
                <LogoSection />
                <OrganizationSelector />
                <Navbar />
                <Integration />
            </div>
            <BottomSection />
        </div>
    )
}

function LogoSection() {
    const [isSidebarOpen, setIsSidebarOpen] = useSidebar()

    return (
        <div className="relative w-full flex justify-end items-center mb-10">
            <div
                className={`absolute flex justify-center left-0 w-[80px] duration-100 ease-linear opacity-100 ${
                    isSidebarOpen ? '' : 'group-hover:opacity-0'
                }`}>
                <img className="w-[50px]" alt="logo" src={Logo} />
            </div>
            <div className="flex justify-center items-center cursor-pointer min-w-[80px] duration-100 ease-linear opacity-0 group-hover:opacity-100 z-10">
                <UI.Icon
                    color="snap-silver"
                    icon={isSidebarOpen ? 'specta-arrow-left' : 'specta-arrow-right'}
                    onClick={() => setIsSidebarOpen(prev => !prev)}
                />
            </div>
        </div>
    )
}

function OrganizationSelector() {
    const currentUser = useCurrentUser()
    const { organizations, selectedOrg, changeOrganization } = useChangeOrganization(asFirebase(firebase))
    const [isOrganizationDropdownOpen, setIsOrganizationDropdownOpen] = useState(false)

    const handleChangeOrganization = async (organization: { value: string; label: string }) => {
        setIsOrganizationDropdownOpen(false)
        await changeOrganization(organization)
    }

    return (
        <>
            <div className="flex items-center mb-16">
                <div className="min-w-[80px] flex shrink-0 justify-center items-center">
                    <div className="duration-300 ease-linear mx-auto">
                        <InitialsCircle initials={currentUser.initials} />
                    </div>
                </div>
                <div className="flex flex-col shrink-0 w-[150px]">
                    <UI.Text size="xs" style="bold" weight="bold">
                        {currentUser.name}
                    </UI.Text>
                    <div className="flex flex-col">
                        <div className="flex items-center gap-x-2 cursor-pointer" onClick={() => setIsOrganizationDropdownOpen(true)}>
                            <UI.Text size="xs" color="snap-red">
                                {selectedOrg?.label}
                            </UI.Text>
                            <UI.Icon color="snap-red" className="rotate-90" size="xs" icon="specta-arrow-right" />
                        </div>
                        <div className="relative flex justify-end">
                            {isOrganizationDropdownOpen && (
                                <ContextMenu
                                    className="max-h-[500px] w-fit"
                                    overflow="auto"
                                    onBlur={() => setIsOrganizationDropdownOpen(false)}>
                                    <UI.List
                                        items={organizations}
                                        render={option => {
                                            return (
                                                <UI.Pressable
                                                    className="hover:bg-snap-teal-100 py-2 px-4"
                                                    key={option.value}
                                                    onPress={() => handleChangeOrganization(option)}>
                                                    {option.label}
                                                </UI.Pressable>
                                            )
                                        }}
                                    />
                                </ContextMenu>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function Navbar() {
    const {
        LL: { navigation }
    } = useI18nContext()

    const { isFeatureOn } = useFeatureToggle()

    const settingsSharedNavList = [
        {
            to: 'units',
            label: navigation.units()
        },
        {
            to: 'users',
            label: navigation.users()
        },
        {
            to: 'rules',
            label: navigation.rules()
        },
        {
            to: 'account',
            label: navigation.account()
        }
    ]

    return (
        <div className="flex flex-col gap-y-4 mb-5 pr-8">
            <NavbarLink to="housekeeping" label={navigation.housekeeping()} icon="specta-grid-2" />
            <NavbarLink to="taskboard" label={navigation.taskboard()} icon="specta-checkmark" />
            <NavbarLink to="issues" label={navigation.issues()} icon="specta-issues" />
            <ExpandableNavbarLink
                parent="reports"
                label={navigation.reports()}
                icon="specta-chart"
                nestedLinks={[
                    {
                        to: 'housekeeping',
                        label: navigation.housekeeping()
                    },
                    {
                        to: 'issues',
                        label: navigation.issues()
                    },
                    {
                        to: 'workload',
                        label: navigation.workload()
                    },
                    {
                        to: 'optional',
                        label: navigation.optional()
                    }
                ]}
            />
            <ExpandableNavbarLink
                parent="settings"
                label={navigation.settings()}
                icon="specta-settings"
                nestedLinks={
                    isFeatureOn('hashtag-killer')
                        ? [
                              ...settingsSharedNavList,
                              {
                                  to: 'categories',
                                  label: navigation.categories()
                              }
                          ]
                        : settingsSharedNavList
                }
            />
            <NavbarLink openInNewTab to="https://help.getsweeply.com" label={navigation.help()} icon="specta-quick_guide" />
        </div>
    )
}

function NavbarItem({ label, icon, isActive, horizontal }: { label: string; icon: Icon; isActive: boolean; horizontal: boolean }) {
    const [isSidebarOpen] = useSidebar()
    const tooltipId = `nav-item-${label.toLowerCase()}`
    const { hoveredColor, setIsHovered } = useHoveredItem(isActive)

    return (
        <div
            className="flex items-center cursor-pointer"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            role="menuitem"
            aria-label={label}>
            <div data-tooltip-id={tooltipId} className={`flex ${horizontal ? 'min-w-[40px]' : 'min-w-[80px]'} justify-center items-center`}>
                <UI.Icon icon={icon} color={hoveredColor} />
            </div>
            <UI.Text color={hoveredColor} weight={isActive ? 'bold' : 'normal'}>
                {label}
            </UI.Text>
            {!isSidebarOpen && <Tooltip place="right" text={label} id={tooltipId} />}
        </div>
    )
}

const NavbarLink = ({
    to,
    label,
    icon,
    openInNewTab = false,
    horizontal = false
}: {
    to: string
    label: string
    icon: Icon
    openInNewTab?: boolean
    horizontal?: boolean
}) => {
    const linkWithPrefix = `${prefix}/${to}`
    const { location } = useRouterState()
    const isActive = location.pathname.startsWith(linkWithPrefix)

    return (
        <Link
            target={openInNewTab ? '_blank' : '_self'}
            className="flex items-center cursor-pointer"
            to={openInNewTab ? to : linkWithPrefix}>
            <NavbarItem label={label} icon={icon} isActive={isActive} horizontal={horizontal} />
        </Link>
    )
}

function ExpandableNavbarLinkItem({
    to,
    label,
    parent,
    horizontal = false
}: {
    to: string
    label: string
    parent: string
    horizontal?: boolean
}) {
    const { location } = useRouterState()
    const linkWithPrefix = `${prefix}/${parent}/${to}`
    const { hoveredColor, setIsHovered } = useHoveredItem(location.pathname.includes(linkWithPrefix))

    return (
        <Link
            className={`${horizontal ? 'py-2 px-4' : 'ml-[80px]'} flex gap-x-2 items-center min-h-[25px] shrink-0`}
            to={linkWithPrefix}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            {!horizontal && <UI.Text color={hoveredColor}>&#8226;</UI.Text>}
            <UI.Text color={hoveredColor}>{label}</UI.Text>
        </Link>
    )
}

function ExpandableNavbarLink({
    parent,
    label,
    icon,
    nestedLinks,
    horizontal = false
}: {
    parent: string
    label: string
    icon: Icon
    nestedLinks: { to: string; label: string }[]
    horizontal?: boolean
}) {
    const [isSidebarOpen, setIsSidebarOpen] = useSidebar()
    const { location } = useRouterState()
    const [isExpanded, setIsExpanded] = useState(false)
    const isActive = location.pathname.includes(`${prefix}/${parent}`)
    const expandableLinkRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (!isSidebarOpen) {
            setIsExpanded(false)
        }
    }, [isSidebarOpen])

    useEffect(() => {
        if (expandableLinkRef.current) {
            if (isExpanded) {
                expandableLinkRef.current.style.maxHeight = `${expandableLinkRef.current.scrollHeight + 10}px`
            } else {
                expandableLinkRef.current.style.maxHeight = '0px'
            }
        }
    }, [isExpanded])

    const handleExpand = () => {
        !isSidebarOpen && setIsSidebarOpen(true)
        setIsExpanded(prev => !prev)
    }

    return (
        <div className="flex flex-col">
            <div onClick={handleExpand}>
                <NavbarItem label={label} icon={icon} isActive={isActive} horizontal={horizontal} />
            </div>

            {horizontal ? (
                <div className="relative flex justify-end">
                    {isExpanded && (
                        <ContextMenu className="max-h-[500px] w-fit" overflow="auto" onBlur={() => setIsExpanded(false)}>
                            <UI.List
                                items={nestedLinks}
                                render={props => (
                                    <ExpandableNavbarLinkItem
                                        key={`navbar-link-item-${props.label}`}
                                        horizontal={horizontal}
                                        parent={parent}
                                        {...props}
                                    />
                                )}
                            />
                        </ContextMenu>
                    )}
                </div>
            ) : (
                <div
                    ref={expandableLinkRef}
                    style={{ maxHeight: 0 }}
                    className="flex flex-col gap-y-1 duration-300 ease-linear overflow-hidden">
                    <UI.List
                        items={nestedLinks}
                        render={props => <ExpandableNavbarLinkItem key={`navbar-link-item-${props.label}`} parent={parent} {...props} />}
                    />
                </div>
            )}
        </div>
    )
}

function BottomSection() {
    const [isSidebarOpen] = useSidebar()

    return (
        <div className="flex flex-col gap-y-4">
            <Subscription />
            <div className={`duration-300 ease-linear w-[80px] ${isSidebarOpen ? 'ml-5' : ''} `}>
                <LanguageSwitcher />
            </div>

            <LogoutButton />
        </div>
    )
}

function LogoutButton() {
    const {
        LL: { navigation }
    } = useI18nContext()
    const signOut = useSignOut(() => auth.signOut())
    const { navigate } = useRouter()
    const { hoveredColor, setIsHovered, isHovered } = useHoveredItem()
    const { shutdown } = useIntercom()
    const [isSidebarOpen] = useSidebar()

    async function onLogoutClick() {
        try {
            shutdown()
            await signOut()
            await navigate({ to: `${prefix}/login` })
        } catch (error) {
            console.error('Error signing out', error)
        }
    }

    return (
        <div
            className="flex items-center cursor-pointer"
            onClick={onLogoutClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <div data-tooltip-id="logout" className="flex min-w-[80px] justify-center items-center">
                <i
                    className={`${isHovered ? hoveredColor : 'text-snap-mid-gray'} fas fa-sign-out-alt`}
                    color={isHovered ? hoveredColor : snapColors['snap-mid-gray']}
                />
            </div>
            <UI.Text color={hoveredColor}>{navigation.logout()}</UI.Text>
            {!isSidebarOpen && <Tooltip place="right" text={navigation.logout()} id="logout" />}
        </div>
    )
}

function Subscription({ horizontal = false }: { horizontal?: boolean }) {
    const [isSidebarOpen] = useSidebar()
    const currentSubscription = useCurrentSubscription()
    const { navigate } = useRouter()
    const {
        LL: { subscriptionAside }
    } = useI18nContext()

    if (!currentSubscription || !isSidebarOpen) return null

    const trialDays = moment(currentSubscription.trialEnd).startOf('day').diff(moment().startOf('day'))
    const showUpgradeButtom = currentSubscription.name === 'Basic' || currentSubscription.status === 'trial'

    return (
        <div className={`flex flex-col gap-y-2 ${horizontal ? '' : 'mx-8'}`}>
            <Link to={`${prefix}/settings/account/subscription`}>
                <UI.Text>
                    {currentSubscription.name} {renderIcon(currentSubscription.id)} {subscriptionAside.subscription()}
                </UI.Text>
            </Link>
            {currentSubscription.status === 'trial' && (
                <UI.Text color="snap-red">
                    {trialDays > 0 ? subscriptionAside.trialEnds({ count: trialDays }) : subscriptionAside.trialExpired()}
                </UI.Text>
            )}

            {showUpgradeButtom && !horizontal && (
                <UI.Button
                    onClick={() => navigate({ to: `${prefix}/settings/account/subscription` })}
                    text={subscriptionAside.upgradeBtn()}
                />
            )}
            {showUpgradeButtom && horizontal && (
                <Link to={`${prefix}/settings/account/subscription`}>
                    <UI.Text color="sweeply-red">{subscriptionAside.upgradeBtn()}</UI.Text>
                </Link>
            )}
        </div>
    )
}

function Integration() {
    const currentOrganization = useCurrentOrg()
    const [isSidebarOpen] = useSidebar()

    if (!isSidebarOpen || !currentOrganization || currentOrganization.pms !== 'apaleo') return null

    return (
        <a className="relative flex" target="_blank" href="https://app.apaleo.com/apps/UBLQ-AC-SWEEPLY-ACCOUNTMENUAPPS">
            <img alt="integration-logo" className="pl-8" src={ApaleoIcon} />
            <img className="relative top-[-10px] h-fit" alt="integration-logo" src={ActionIcon} />
        </a>
    )
}

export function HeaderNavbar() {
    const {
        LL: { navigation }
    } = useI18nContext()
    const currentUser = useCurrentUser()
    const currentOrganization = useCurrentOrg()
    const brandLogo = getNavBarAuthLogo()
    const taskboardContext = useRecoilValue(taskboardContextAtom)

    return (
        <div className="flex justify-between items-center">
            <div className="flex items-center gap-x-4">
                <img src={brandLogo.img} style={brandLogo.style} alt="logo" />
                <Subscription horizontal />
            </div>
            <div className="flex gap-x-2">
                <NavbarLink
                    to={taskboardContext === TaskboardContext.EMBEDDED ? 'taskboard' : 'integrations/apaleo/taskboard'}
                    label={navigation.taskboard()}
                    icon="specta-checkmark"
                    horizontal
                />
                <NavbarLink to="housekeeping" label={navigation.housekeeping()} icon="specta-grid-2" horizontal />
                <NavbarLink to="issues" label={navigation.issues()} icon="specta-issues" horizontal />
                <ExpandableNavbarLink
                    horizontal
                    parent="reports"
                    label={navigation.reports()}
                    icon="specta-chart"
                    nestedLinks={[
                        {
                            to: 'housekeeping',
                            label: navigation.housekeeping()
                        },
                        {
                            to: 'issues',
                            label: navigation.issues()
                        },
                        {
                            to: 'workload',
                            label: navigation.workload()
                        },
                        {
                            to: 'optional',
                            label: navigation.optional()
                        }
                    ]}
                />
                <ExpandableNavbarLink
                    horizontal
                    parent="settings"
                    label={navigation.settings()}
                    icon="specta-settings"
                    nestedLinks={[
                        {
                            to: 'units',
                            label: navigation.units()
                        },
                        {
                            to: 'users',
                            label: navigation.users()
                        },
                        {
                            to: 'rules',
                            label: navigation.rules()
                        },
                        {
                            to: 'account',
                            label: navigation.account()
                        }
                    ]}
                />
                <NavbarLink openInNewTab to="https://help.getsweeply.com" label={navigation.help()} icon="specta-quick_guide" horizontal />
            </div>

            <LogoutButton />

            <div className="flex gap-x-2">
                <div className="flex flex-col">
                    <UI.Text>{currentUser.name}</UI.Text>
                    <UI.Text color="sweeply-red">{currentOrganization.name}</UI.Text>
                </div>
                <InitialsCircle initials={currentUser.initials} />
            </div>
        </div>
    )
}
