import { loadLocaleAsync } from '@shared-snap/i18n/i18n-util.async'
import { useAuthState, useCurrentOrg, useCurrentUser, useSidebar } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { useIntercomStateChange } from '@shared-snap/snap/components/auth/hooks/use-intercom-state'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { Navigate, Outlet, useNavigate } from '@tanstack/react-router'
import { asFirestore, db } from 'app/firebase'
import moment from 'moment'
import React, { useEffect } from 'react'
import { prefix } from '../../../routes/__root'
import 'moment/dist/locale/es'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/is'
import 'moment/dist/locale/it'
import 'moment/dist/locale/pl'
import 'moment/dist/locale/uk'
import 'moment/dist/locale/de'
import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import type { Locales } from '@shared-snap/i18n/i18n-types'
import { useDateUpdate } from '@shared-snap/snap/components/auth/hooks/use-date-update'
import { useTaskboardContext } from '@shared-snap/snap/components/my-tasks/hooks/use-tasks'
import type { IntegrationParams } from '@shared-snap/snap/components/my-tasks/state/my-tasks'
import { Analytics } from '@shared/analytics'
import { TaskboardContext } from '@shared/traces-types'
import { Aside, HeaderNavbar } from 'components/organisms/aside'
import { useIntercom } from 'react-use-intercom'
import { getBrowserInfo } from '../../../infra/browser-info'

function Layout() {
    const { setLocale } = useI18nContext()
    const currentUser = useCurrentUser()
    const currentOrganization = useCurrentOrg()
    const navigate = useNavigate()
    const isEmbeddedInLegacyWeb = process.env.VITE_EMBEDDED
    const { locale } = useI18nContext()
    const { taskboardContext } = useTaskboardContext()
    const [isSidebarOpen] = useSidebar()
    const { boot, update, shutdown } = useIntercom()

    useDateUpdate()

    useEffect(() => {
        if (!isEmbeddedInLegacyWeb) {
            currentUser &&
                currentOrganization &&
                useIntercomStateChange(boot, update, shutdown, {
                    currentUser: currentUser,
                    currentOrganization: currentOrganization
                })
        }
    }, [currentUser, currentOrganization])

    useEffect(() => {
        const handleMessage = async (event: MessageEvent<{ path: string; integrationParams: IntegrationParams; lang: Locales }>) => {
            if (event.data.integrationParams) {
                console.log(
                    'Received integrationParams but ignoring those since we are processing them from our own search params',
                    event.data.integrationParams
                )
            }

            if (event.data.path) {
                await navigate({ to: `${prefix}${event.data.path}` as string })
            }

            if (event.data.lang) {
                await loadLocaleAsync(event.data.lang)
                setLocale(event.data.lang)
                moment.locale(event.data.lang)
            } else {
                await loadLocaleAsync('en')
                setLocale('en')
                moment.locale('en')
            }
        }

        window.addEventListener('message', handleMessage)
        window.parent.postMessage({ type: 'iframe-ready' }, '*')

        return () => {
            window.removeEventListener('message', handleMessage)
        }
    }, [])

    useEffect(() => {
        if (currentUser) {
            const browserInfo = getBrowserInfo()
            new Analytics(asFirestore(db))
                .updateWebUserFingerprint({
                    orgKey: currentUser.organizationKey,
                    userKey: currentUser.key,
                    browser: browserInfo.name,
                    agent: browserInfo.userAgent,
                    version: browserInfo.fullVersion,
                    sha: process.env.VITE_BUILD ?? 'unknown',
                    locale: locale?.toString() ?? 'unknown'
                })
                .then(() => console.log('User fingerprint updated'))
                .catch(e => console.error('Error updating user fingerprint', e))
        }
    }, [currentUser])

    if (isEmbeddedInLegacyWeb || taskboardContext !== TaskboardContext.EMBEDDED) {
        return (
            <div className="px-10 pt-2">
                {!isEmbeddedInLegacyWeb && <HeaderNavbar />}

                <div className="mt-[64px]">
                    <Outlet />
                </div>
            </div>
        )
    }
    return (
        <div className="flex w-full">
            <Aside />

            <div className={`duration-300 ease-linear w-full ${isSidebarOpen ? 'pl-[248px]' : 'pl-[80px]'} pt-[10px]`}>
                <div className="px-12 h-screen flex flex-col py-20">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

function AuthenticatedLayout() {
    const authState = useAuthState()

    if (authState === 'loading') {
        return (
            <div className="h-screen w-screen">
                <UI.Loader />
            </div>
        )
    }
    if (authState === 'not-loggedin') {
        return <Navigate to={`${prefix}/login`} />
    }

    return <Layout />
}

export default AuthenticatedLayout
