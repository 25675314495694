import type React from 'react'
import { useCallback, useEffect, useState } from 'react'
import type { FieldValues, Path, UseFormRegister } from 'react-hook-form'

export interface FieldInput<TFieldValues extends FieldValues = FieldValues> {
    name: Path<TFieldValues>
    placeholder: string
    register?: UseFormRegister<TFieldValues>
    required?: boolean
    type?: React.InputHTMLAttributes<HTMLInputElement>['type']
    initialValue?: string
    onChange?: (value: string) => void
    className?: string
    disabled?: boolean
    onSave?: (value: string) => void
    onClick?: () => void
    min?: number
    max?: number
}

function Input<TFieldValues extends FieldValues = FieldValues>({
    name,
    placeholder,
    register,
    required,
    type,
    initialValue,
    className,
    onChange,
    disabled,
    onSave,
    onClick,
    min,
    max
}: FieldInput<TFieldValues>) {
    'use no memo'
    const [value, setValue] = useState(initialValue || '')

    useEffect(() => {
        setValue(initialValue || '')
    }, [initialValue])

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value
            setValue(newValue)
            if (onChange) {
                onChange(newValue)
            }
        },
        [onChange]
    )

    const handlePressEnter = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value
        setValue(newValue)

        if (event.key === 'Enter') {
            event.currentTarget.blur()
        }
    }, [])

    const handleBlur = useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
            const newValue = event.target.value
            setValue(newValue)
            if (onSave) {
                onSave(newValue)
            }
        },
        [onSave]
    )

    return (
        <input
            disabled={disabled}
            className={`py-2 border-b border-snap-light-gray focus:outline-none focus:ring-0 placeholder:text-sm ${className}`}
            placeholder={placeholder}
            type={type || 'text'}
            onWheel={event => {
                if (type === 'number') {
                    event.currentTarget.blur()
                }
            }}
            min={min}
            max={max}
            minLength={min}
            maxLength={max}
            id={name}
            {...(register
                ? register(name, { required: required ?? false })
                : { onChange: handleChange, onKeyDown: handlePressEnter, onBlur: handleBlur, onClick: onClick, value: value ?? '' })}
        />
    )
}

export default Input
