'use no memo'

import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCurrentUser } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { useBookingExample } from '@shared-snap/snap/components/settings/hooks/use-rules'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { Option } from '@shared-snap/snap/types/ui-types'
import { pickCleaningStatusColor, pickOccupancyIcons } from '@shared-snap/snap/utils/housekeeping-utils'
import type { RuleTrigger } from '@shared/firestore-structs'
import { Checkbox } from 'components/atoms/checkbox'
import { DatePicker } from 'components/atoms/date-picker/date-picker'
import { FieldErrorMessage } from 'components/atoms/field-error-message'
import { FieldLabel } from 'components/atoms/field-label'
import { FieldSelect } from 'components/atoms/field-select'
import Input from 'components/atoms/input/input'
import TextAreaInput from 'components/atoms/text-area-input'
import { SelectDateTrigger } from 'components/molecules/date-field'
import { Suspense } from 'react'
import { Controller, type FieldValues, type Path, type UseFormRegister, useFormContext } from 'react-hook-form'

function TriggerRadio({
    header,
    body,
    value,
    register
}: {
    header: string
    body: string
    value: RuleTrigger
    register: UseFormRegister<FieldValues>
}) {
    const { setValue } = useFormContext()
    return (
        <div
            className="flex p-4 w-full border border-snap-silver rounded-md items-start gap-x-2 cursor-pointer"
            onClick={() => setValue('trigger', value)}>
            <input value={value} type="radio" {...register('trigger')} />

            <div className="flex flex-col gap-y-2">
                <UI.Text height="none">{header}</UI.Text>
                <UI.Text size="xs" color="snap-mid-gray">
                    {body}
                </UI.Text>
            </div>
        </div>
    )
}

function CheckboxSection({
    name,
    label,
    description,
    isWarning = false
}: { name: string; label: string; description: string; isWarning?: boolean }) {
    return (
        <div>
            <div className="flex items-center gap-x-2">
                <Checkbox name={name} />
                <label htmlFor={name}>{label}</label>
            </div>
            <UI.Text weight="normal" color={isWarning ? 'sweeply-curry-yellow' : 'snap-gray'}>
                {description}
            </UI.Text>
        </div>
    )
}

function ShortInput({
    name,
    label,
    min,
    max,
    type,
    placeholder,
    register,
    description
}: {
    name: Path<FieldValues>
    label: string
    min: number
    max: number
    type: string
    placeholder: string
    register: UseFormRegister<FieldValues>
    description: string
}) {
    'use no memo'
    return (
        <FieldLabel horizontal label={label}>
            <div className="flex items-center gap-x-4 w-[350px]">
                <Input className="w-1/2" min={min} max={max} type={type} name={name} placeholder={placeholder} register={register} />
                <label className="flex shrink-0 w-1/2" htmlFor={name}>
                    {description}
                </label>
            </div>
        </FieldLabel>
    )
}

function StayRestrictionsSection() {
    const { register } = useFormContext()
    const {
        LL: { settingsWeb, shared }
    } = useI18nContext()

    return (
        <>
            <FieldLabel label={`${settingsWeb.ruleForm.minStay()}:`}>
                <Input type="number" min={0} name="minStay" placeholder={settingsWeb.ruleForm.minStay()} register={register} />
            </FieldLabel>
            <FieldLabel label={`${settingsWeb.ruleForm.maxStay()}:`}>
                <Input type="number" min={0} name="maxStay" placeholder={settingsWeb.ruleForm.maxStay()} register={register} />
            </FieldLabel>
            <FieldLabel label={`${settingsWeb.ruleForm.customCharacter()}:`}>
                <Input max={1} name="customChar" placeholder={settingsWeb.ruleForm.customCharacter()} register={register} />
            </FieldLabel>
            <FieldLabel label={`${shared.priority()}:`}>
                <Input type="number" min={0} name="priority" placeholder={shared.priority()} register={register} />
            </FieldLabel>
        </>
    )
}

export function SetupStep() {
    const currentUser = useCurrentUser()
    const {
        register,
        formState: { errors },
        watch
    } = useFormContext()
    const {
        LL: { settingsWeb, shared }
    } = useI18nContext()

    const trigger = watch('trigger')

    return (
        <div className="flex flex-col gap-y-10">
            <FieldLabel label={`${settingsWeb.ruleForm.nameLabel()}:`} required>
                <Input required name="name" placeholder={settingsWeb.ruleForm.nameLabel()} register={register} />
                {errors.name && <FieldErrorMessage message={shared.errorFieldIsRequired({ field: settingsWeb.ruleForm.nameLabel() })} />}
            </FieldLabel>
            <FieldLabel label={`${settingsWeb.ruleForm.descriptionLabel()}:`}>
                <TextAreaInput name="description" placeholder={settingsWeb.ruleForm.descriptionPlaceholder()} register={register} />
            </FieldLabel>
            <FieldLabel label={`${settingsWeb.ruleForm.triggerLabel()}:`}>
                <div className="flex gap-x-4">
                    <TriggerRadio
                        value="booking"
                        header={settingsWeb.ruleForm.bookingTriggerHeader()}
                        body={settingsWeb.ruleForm.bookingTriggerBody()}
                        register={register}
                    />
                    <TriggerRadio
                        value="date"
                        header={settingsWeb.ruleForm.dateTriggerHeader()}
                        body={settingsWeb.ruleForm.dateTriggerBody()}
                        register={register}
                    />
                </div>
            </FieldLabel>

            {trigger === 'booking' ? <BookingSection /> : <DateSection />}

            {currentUser.authSuper && <StayRestrictionsSection />}
        </div>
    )
}

function BookingSection() {
    const currentUser = useCurrentUser()
    const { watch } = useFormContext()
    const {
        LL: { settingsWeb }
    } = useI18nContext()

    const repeatType = watch('repeatType')
    const inspection = watch('inspection')

    const repeatTypes: Option[] = [
        { value: 'checkout', label: settingsWeb.rulesRepeatType.checkout() },
        { value: 'custom', label: settingsWeb.rulesRepeatType.custom() },
        ...(currentUser.authSuper ? [{ value: 'optin', label: settingsWeb.rulesRepeatType.optin() }] : [])
    ]

    return (
        <div className="flex flex-col gap-y-10">
            <FieldLabel horizontal label={`${settingsWeb.ruleForm.repeatOnLabel()}:`} className="!flex-col !justify-start !w-fit">
                <FieldSelect
                    defaultValue={undefined}
                    name="repeatType"
                    options={repeatTypes}
                    placeholder={settingsWeb.ruleForm.repeatOnLabel()}
                    Option={({ data, innerProps }) => (
                        <div {...innerProps} className="flex flex-col w-full gap-1 p-2 border-b cursor-pointer">
                            <UI.Text size="md" weight="bold">
                                {data.label}
                            </UI.Text>
                        </div>
                    )}
                />
            </FieldLabel>

            {repeatType.value === 'checkout' && (
                <CheckboxSection
                    name="inspection"
                    label={settingsWeb.ruleForm.inspectionLabel()}
                    description={settingsWeb.ruleForm.inspectionDescription()}
                    isWarning
                />
            )}
            <CheckboxSection
                name="isDefaultRule"
                label={settingsWeb.ruleForm.defaultRuleLabel()}
                description={settingsWeb.ruleForm.defaultRuleDescription()}
                isWarning
            />

            {repeatType.value === 'custom' && <CustomRepeatTypeSection />}

            {!inspection && <Example />}
        </div>
    )
}

function DateSection() {
    const { control, register, watch, setValue } = useFormContext()
    const startDate = watch('start')
    const {
        LL: { settingsWeb }
    } = useI18nContext()

    return (
        <Suspense fallback={null}>
            <div className="flex flex-col gap-y-10 w-1/2">
                <CheckboxSection
                    name="isDefaultRule"
                    label={settingsWeb.ruleForm.defaultRuleLabel()}
                    description={settingsWeb.ruleForm.defaultRuleDescription()}
                    isWarning
                />
                <ShortInput
                    name="repeatInterval"
                    label={`${settingsWeb.ruleForm.repeatIntervalLabel()}:`}
                    min={0}
                    max={10}
                    type="number"
                    placeholder={settingsWeb.ruleForm.pickNumberPlaceholder()}
                    register={register}
                    description={settingsWeb.ruleForm.daysPlaceholder()}
                />
                <FieldLabel label={`${settingsWeb.rules.startDateColumn()}:`}>
                    <Controller
                        control={control}
                        defaultValue={startDate}
                        name="start"
                        render={({ field: { value, onChange } }) => (
                            <DatePicker
                                value={value}
                                withTrigger={{ id: 'dueDate', Trigger: <SelectDateTrigger value={value} /> }}
                                onChange={onChange}
                            />
                        )}
                    />
                </FieldLabel>
            </div>
        </Suspense>
    )
}

function CustomRepeatTypeSection() {
    'use no memo'
    const { register } = useFormContext()
    const {
        LL: { settingsWeb }
    } = useI18nContext()

    return (
        <div className="flex flex-col gap-y-2 w-[500px] max-w-full">
            <ShortInput
                name="repeatOffsetStart"
                label={`${settingsWeb.ruleForm.startLabel()}:`}
                min={0}
                max={10}
                type="number"
                placeholder={settingsWeb.ruleForm.pickNumberPlaceholder()}
                register={register}
                description={settingsWeb.ruleForm.checkinDaysPlaceholder()}
            />
            <ShortInput
                name="repeatOffsetEnd"
                label={`${settingsWeb.ruleForm.endLabel()}:`}
                min={0}
                max={10}
                type="number"
                placeholder={settingsWeb.ruleForm.pickNumberPlaceholder()}
                register={register}
                description={settingsWeb.ruleForm.checkoutDaysPlaceholder()}
            />
            <ShortInput
                name="repeatInterval"
                label={`${settingsWeb.ruleForm.repeatIntervalLabel()}:`}
                min={0}
                max={10}
                type="number"
                placeholder={settingsWeb.ruleForm.pickNumberPlaceholder()}
                register={register}
                description={settingsWeb.ruleForm.daysPlaceholder()}
            />
        </div>
    )
}

function Example() {
    const { watch } = useFormContext()
    const { minStay, maxStay, repeatInterval, repeatType, repeatOffsetStart, repeatOffsetEnd } = watch()

    const { result, bookingDates } = useBookingExample({
        minStay,
        maxStay,
        repeatInterval,
        repeatType: repeatType.value,
        repeatOffsetStart,
        repeatOffsetEnd
    })
    const {
        LL: { settingsWeb, shared }
    } = useI18nContext()

    if (result.length === 0) return null

    return (
        <div>
            <div className="mb-4">
                <UI.Text size="lg">{settingsWeb.ruleForm.exampleTitle()}:</UI.Text>
            </div>
            <div className="grid grid-cols-6 gap-y-4 w-full">
                {result.map((item, index) => {
                    const title =
                        index === 0
                            ? shared.occupancy.checkin()
                            : index === bookingDates.length - 1
                              ? shared.occupancy.checkout()
                              : index + 1
                    const color = item.showIcon
                        ? pickCleaningStatusColor({ cleaningStatus: item.cleaningStatus, occupancy: item.occupancy }) + ' text-white'
                        : 'gray-200'

                    return (
                        <div className="flex justify-start" key={title}>
                            <div className={`bg-${color} p-2 rounded-md w-[80px] h-[80px] flex flex-col items-center`}>
                                <div className="h-1/3">
                                    {item.showIcon && <UI.Icon size="xs" icon={pickOccupancyIcons(item.occupancy)} />}
                                </div>
                                <div className="h-1/3">
                                    <UI.Text size="xs">{title}</UI.Text>
                                </div>
                                <div className="h-1/3" />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
