import type { Firebase } from '@shared/firebase'
import type { AreaCleaningStatus, Occupancy, RuleRepeatType, RuleStruct } from '@shared/firestore-structs'
import { getRule } from '@shared/rules-data'
import moment from 'moment'
import { useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useCurrentUser } from '../../auth/hooks/use-auth-state'
import { currentRuleWizardStep, rulesAtom } from '../state/rules-state'

interface BookingExample {
    minStay: number
    maxStay: number
    repeatInterval: number
    repeatType: RuleRepeatType
    repeatOffsetStart: number
    repeatOffsetEnd: number
}

interface ExampleDay {
    showIcon: boolean
    cleaningStatus: AreaCleaningStatus
    occupancy: Occupancy
}

const isStayWithinRule = (lengthOfStay: number, min: number, max: number): boolean => {
    const isUndefinedOrEmpty = (prop: any) => {
        return prop === undefined || prop === ''
    }
    const isMinStayValid = !isUndefinedOrEmpty(min) ? lengthOfStay >= min! : true
    const isMaxStayValid = !isUndefinedOrEmpty(max) ? lengthOfStay <= max! : true

    return isMinStayValid && isMaxStayValid
}

export function useRules() {
    return useRecoilState(rulesAtom)
}

export function useEditedRule(firebase: Firebase) {
    const rules = useRecoilValue(rulesAtom)

    async function fetchRule(ruleKey?: string | null): Promise<RuleStruct | null> {
        if (!ruleKey) return null

        const localRule = rules.find(rule => rule.key === ruleKey)

        if (localRule) return localRule

        const firebaseRule = await getRule(firebase, ruleKey)
        return firebaseRule
    }

    return { fetchRule }
}

export function useCurrentRuleWizardStep() {
    return useRecoilState(currentRuleWizardStep)
}

export function useDeleteRule(firebase: Firebase) {
    const currentUser = useCurrentUser()
    const db = firebase.firestore()

    const deleteRule = async (rules: RuleStruct[]) => {
        try {
            const batch = db.batch()

            rules.map(rule => {
                return batch.update(db.collection('rules').doc(rule.key), {
                    visible: false,
                    updated: moment().valueOf(),
                    lastChangedByUser: currentUser.key
                })
            })

            await batch.commit()
        } catch (error) {
            console.error(error)
        }
    }

    return deleteRule
}

export function useBookingExample({ minStay, maxStay, repeatInterval, repeatType, repeatOffsetStart, repeatOffsetEnd }: BookingExample) {
    'use no memo'
    const min = Number(minStay ?? 0) ?? 0
    const max = Number(maxStay ?? 0) ?? 0
    const interval = Number(repeatType === 'custom' ? repeatInterval : 1) ?? 1

    const initNumber = useMemo(() => {
        switch (repeatType) {
            case 'custom':
                if (min && !max) {
                    return min + 1
                } else if (!min && max) {
                    return max / interval
                } else if (min && max) {
                    return max - 1
                } else {
                    return 5
                }
            case 'checkout':
                return min ? min + 1 : 5
            default:
                return 5
        }
    }, [min, max, interval, repeatType])

    const bookingDates = useMemo(
        () =>
            [...Array(Math.ceil(initNumber * (interval > 0 ? interval : 1)))].map((_, i) => {
                return moment().add(i, 'days').startOf('day').valueOf()
            }),
        [initNumber, interval]
    )

    const dates = bookingDates.slice(1, -1)
    const startIndex = repeatOffsetStart - 1
    const endIndex = dates.length - repeatOffsetEnd

    const filteredDates = dates.filter((_, index) => {
        const isWithinRange = index >= startIndex && index <= endIndex
        const matchesInterval = interval === 1 || (index - startIndex) % interval === 0
        return isWithinRange && matchesInterval
    })

    const result: ExampleDay[] = useMemo(() => {
        const defaultDay: ExampleDay = {
            showIcon: false,
            cleaningStatus: 'clean',
            occupancy: 'vacant'
        }

        if (repeatType === 'optin') {
            return bookingDates.map(() => defaultDay)
        }

        if (repeatType === 'checkout') {
            const checkoutDate = bookingDates[bookingDates.length - 1]

            if (min !== 0 && max !== 0 && !isStayWithinRule(bookingDates.length - 1, min, max)) {
                return bookingDates.map(() => defaultDay)
            } else {
                return bookingDates.map(date => ({
                    showIcon: checkoutDate === date,
                    cleaningStatus: checkoutDate === date ? 'waiting-for-checkout' : 'clean',
                    occupancy: checkoutDate === date ? 'checkout' : 'vacant'
                }))
            }
        }

        return bookingDates.map(date => ({
            showIcon: filteredDates.includes(date),
            cleaningStatus: 'dirty',
            occupancy: interval === 1 ? 'stayover' : 'stayover-80'
        }))
    }, [interval, filteredDates, bookingDates, min, max, repeatType])

    return { result, bookingDates }
}
