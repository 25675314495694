'use no memo'

import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import type { Translation } from '@shared-snap/i18n/i18n-types'
import { useCurrentOrg } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { useCurrentRuleWizardStep, useEditedRule } from '@shared-snap/snap/components/settings/hooks/use-rules'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { Icon } from '@shared-snap/snap/types/icon-font.enum'
import type { RuleRepeatType, RuleTrigger } from '@shared/firestore-structs'
import { saveRule } from '@shared/rules-data'
import { useNavigate } from '@tanstack/react-router'
import firebase, { asFirebase } from 'app/firebase'
import { Button } from 'components/atoms/button'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { AssignStep } from './assign-step'
import { ChecklistStep } from './checklist-step'
import { SetupStep } from './setup-step'

const repeatTypes = ['checkout', 'custom', 'optin']

interface RuleForm {
    name: string
    description: string
    trigger: RuleTrigger
    repeatType: { value: RuleRepeatType; label: string }
    inspection: boolean
    isDefaultRule: boolean
    repeatOffsetStart: number
    repeatOffsetEnd: number
    repeatInterval: number
    priority: number
    end: number
    start: number
    areas: string[]
    minStay?: number
    maxStay?: number
    customChar?: string
    checklistTasks?: string[]
}

const stepsConfig: {
    id: number
    icon: Icon
    isLast?: boolean
    key: string
}[] = [
    { id: 1, key: 'setupStep', icon: 'specta-schedule' },
    { id: 2, key: 'checklistStep', icon: 'specta-issues' },
    { id: 3, key: 'assignStep', icon: 'specta-add-rule-to-room', isLast: true }
]

export function RuleForm({ ruleKey, step, navigate }: { ruleKey: string | null; step: number; navigate: (step: number) => void }) {
    const {
        LL: { settingsWeb }
    } = useI18nContext()

    return (
        <div className="flex flex-col grow shrink-0">
            <Header text={ruleKey ? settingsWeb.ruleForm.editRule() : settingsWeb.ruleForm.newRule()} />
            <Wizard />
            <Content routeNavigate={navigate} step={step} ruleKey={ruleKey} />
        </div>
    )
}

function Header({ text }: { text: string }) {
    return <h1 className="text-3xl font-bold mb-10">{text}</h1>
}

function WizardStep({ text, icon, isActive, isLastStep = false }: { text: string; icon: Icon; isActive: boolean; isLastStep?: boolean }) {
    return (
        <div className="flex items-center">
            <div className="flex flex-col items-center px-10">
                <i className={`${icon} text-[48px] ${isActive ? 'text-snap-dark-blue' : 'text-snap-silver'}`} />
                <UI.Text color={isActive ? 'snap-dark-blue' : 'snap-silver'}>{text}</UI.Text>
            </div>
            {!isLastStep && <UI.Icon color={isActive ? 'snap-dark-blue' : 'snap-silver'} size="2xl" icon="specta-arrow-next" />}
        </div>
    )
}

function Wizard() {
    const [currentStep] = useCurrentRuleWizardStep()
    const {
        LL: { settingsWeb }
    } = useI18nContext()

    return (
        <div className="flex items-center justify-center mb-10">
            {stepsConfig.map(({ id, key, icon, isLast }) => (
                <WizardStep
                    key={key}
                    text={`${id}. ${settingsWeb.ruleForm.steps[key as keyof Translation['settingsWeb']['ruleForm']['steps']]()}`}
                    icon={icon}
                    isLastStep={isLast}
                    isActive={id === currentStep}
                />
            ))}
        </div>
    )
}

function Content({ ruleKey, step, routeNavigate }: { ruleKey: string | null; step: number; routeNavigate: (step: number) => void }) {
    const { fetchRule } = useEditedRule(asFirebase(firebase))
    const [currentStep, setCurrentStep] = useCurrentRuleWizardStep()
    const currentOrganization = useCurrentOrg()
    const [ruleIsLoading, setRuleIsLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const i18nContext = useI18nContext()

    const defaultValues: RuleForm = useMemo(
        () => ({
            name: '',
            description: '',
            trigger: 'booking',
            repeatType: { value: 'custom', label: i18nContext.LL.settingsWeb.rulesRepeatType.custom() },
            inspection: false,
            isDefaultRule: false,
            repeatOffsetStart: 1,
            repeatOffsetEnd: 1,
            repeatInterval: 1,
            priority: 2,
            end: moment().valueOf(),
            start: moment().valueOf(),
            areas: [],
            checklistTasks: []
        }),
        [i18nContext]
    )

    const form = useForm<RuleForm>({
        defaultValues
    })

    const { handleSubmit, reset } = form

    useEffect(() => {
        const loadRule = async () => {
            try {
                setRuleIsLoading(true)
                const editedRule = await fetchRule(ruleKey)

                if (editedRule) {
                    reset({
                        name: editedRule.name ?? '',
                        description: editedRule.description ?? '',
                        trigger: editedRule.trigger ?? 'booking',
                        repeatType: repeatTypes.includes(editedRule.repeatType)
                            ? { value: editedRule.repeatType, label: i18nContext.LL.settingsWeb.rulesRepeatType[editedRule.repeatType]() }
                            : { value: 'custom', label: i18nContext.LL.settingsWeb.rulesRepeatType.custom() },
                        inspection: editedRule.inspection ?? false,
                        isDefaultRule: editedRule.isDefaultRule ?? false,
                        repeatOffsetStart: editedRule.repeatOffsetStart ?? 1,
                        repeatOffsetEnd: editedRule.repeatOffsetEnd ?? 1,
                        repeatInterval: editedRule.repeatInterval ?? 1,
                        priority: editedRule.priority ?? 2,
                        end: editedRule.end ?? moment().valueOf(),
                        start: editedRule.start ?? moment().valueOf(),
                        areas: editedRule.areas ?? [],
                        checklistTasks: editedRule.checklistTasks ?? [],
                        minStay: editedRule.minStay ?? 1,
                        maxStay: editedRule.maxStay ?? 1,
                        customChar: editedRule.customChar
                    })
                } else {
                    reset(defaultValues)
                }
            } catch (error) {
                console.error('Failed to fetch rule:', error)
            } finally {
                setRuleIsLoading(false)
            }
        }

        loadRule()
    }, [ruleKey, defaultValues, i18nContext, fetchRule, reset])

    useEffect(() => {
        setCurrentStep(step ?? 1)
    }, [step, setCurrentStep])

    const backToTable = () => navigate({ to: '/snap/settings/rules' })

    const onSubmit = async (data: RuleForm) => {
        setIsLoading(true)

        try {
            await saveRule(asFirebase(firebase), {
                ...data,
                key: ruleKey ?? undefined,
                repeatType: data.repeatType.value,
                organizationKey: currentOrganization.key
            })
            setCurrentStep(1)
            backToTable()
        } catch (error) {
            console.error('Failed to save rule:', error)
        } finally {
            setIsLoading(false)
        }
    }

    if (ruleIsLoading) {
        return (
            <div className="flex grow items-center justify-center">
                <UI.Loader />
            </div>
        )
    }

    return (
        <>
            <FormProvider {...form}>
                <form className="flex flex-col grow gap-y-10" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex items-center justify-center">
                        <div className="max-w-[800px]">
                            {currentStep === 1 && <SetupStep />}
                            {currentStep === 2 && <ChecklistStep />}
                            {currentStep === 3 && <AssignStep />}
                        </div>
                    </div>
                    <ButtonsSection navigateBack={backToTable} navigate={routeNavigate} isLoading={isLoading} />
                </form>
            </FormProvider>
        </>
    )
}

function ButtonsSection({
    isLoading,
    navigate,
    navigateBack
}: {
    isLoading: boolean
    navigate: (step: number) => void
    navigateBack: () => void
}) {
    const [currentStep, setCurrentStep] = useCurrentRuleWizardStep()
    const isLastStep = currentStep === stepsConfig.length
    const { trigger } = useFormContext()
    const {
        LL: { settingsWeb, shared }
    } = useI18nContext()

    const handleCancel = () => {
        navigateBack()
    }

    const handleNext = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        const isValid = await trigger()

        if (isValid) {
            const nextStep = Math.min(currentStep + 1, stepsConfig.length)
            setCurrentStep(nextStep)
            navigate(nextStep)
        }
    }

    const handleBack = () => {
        const prevStep = Math.max(currentStep - 1, 1)
        setCurrentStep(prevStep)
        navigate(prevStep)
    }

    return (
        <div className="flex justify-between">
            {isLoading ? (
                <div className="flex grow items-center justify-center">
                    <UI.Loader />
                </div>
            ) : (
                <>
                    <Button type="button" onClick={handleCancel}>
                        {shared.cancel()}
                    </Button>
                    <div className="flex gap-x-4">
                        <Button type="button" disabled={currentStep === 1} onClick={handleBack}>
                            {settingsWeb.ruleForm.backButton()}
                        </Button>
                        {isLastStep ? (
                            <Button type="submit">{shared.save()}</Button>
                        ) : (
                            <Button type="button" onClick={handleNext}>
                                {settingsWeb.ruleForm.nextButton()}
                            </Button>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}
