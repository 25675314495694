import { DatePicker } from 'components/atoms/date-picker/date-picker'
import moment from 'moment'
import { Controller, useFormContext } from 'react-hook-form'

export function DateField({ defaultValue }: { defaultValue: string }) {
    const { control } = useFormContext<{ dueDate: typeof defaultValue }>()

    return (
        <Controller
            control={control}
            defaultValue={defaultValue}
            name="dueDate"
            render={({ field: { value, onChange } }) => (
                <DatePicker
                    value={value}
                    withTrigger={{ id: 'dueDate', Trigger: <SelectDateTrigger value={value} /> }}
                    onChange={onChange}
                />
            )}
        />
    )
}

export function SelectDateTrigger({ value }: { value: string | Date | number }) {
    const btnText = moment(value).format('LL') ?? 'Select date'

    return (
        <button
            type="button"
            className={`border rounded-lg py-1 px-2 text-${value ? 'snap-light-blue' : 'snap-silver'} border-${
                value ? 'snap-light-blue' : 'snap-light-gray'
            }`}>
            {btnText}
        </button>
    )
}
