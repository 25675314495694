import * as colors from '@shared/colors'
import { iOSColors } from '@shared/react-native-typography'
export type { Icon } from './icon-font.enum'
export { IconFontMap } from './icon-font.enum'
export const cleaningStatusColors = {
    'sweeply-rose': colors.rose_unclean,
    'sweeply-red': colors.red_spectaflow,
    'sweeply-yellow': colors.yello_spectaflow,
    'sweeply-curry-yellow': colors.curry_yellow_inspection,
    'sweeply-green': iOSColors.green,
    'sweeply-blue': colors.blue_spectaflow,
    'sweeply-mid-gray': iOSColors.midGray,
    'sweeply-light-gray': iOSColors.lightGray,
    'sweeply-gray': iOSColors.gray,
    'sweeply-teal': colors.teal_sweeply,
    'sweeply-purple': colors.purple_spectaflow,
    'sweeply-black': iOSColors.black
}

export const snapColors = {
    'snap-white': colors.SnapColors.white,
    'snap-silver': colors.SnapColors.silver,
    'snap-gray': colors.SnapColors.gray,
    'snap-light-gray': colors.SnapColors.lightGray,
    'snap-rose': colors.SnapColors.rose,
    'snap-red': colors.SnapColors.red,
    'snap-yellow': colors.SnapColors.yellow,
    'snap-teal': colors.SnapColors.teal,
    'snap-teal-100': colors.SnapColors.teal100,
    'snap-light-silver': colors.SnapColors.lightSilver,
    'snap-mid-silver': colors.SnapColors.midSilver,
    'snap-light-blue': colors.SnapColors.lightBlue,
    'snap-black': colors.SnapColors.black,
    'snap-transparent-black': colors.SnapColors.transparentBlack,
    'snap-mid-gray': colors.SnapColors.midGray,
    'snap-light-yellow': colors.SnapColors.lightYellow,
    'snap-green': colors.SnapColors.green,
    'snap-dark-gray': colors.SnapColors.darkGray,
    'snap-dark-blue': colors.SnapColors.darkBlue
}

export type CleaningStatusColor = keyof typeof cleaningStatusColors
export type SnapColor = keyof typeof snapColors

export type Colors =
    | CleaningStatusColor
    | SnapColor
    | 'white'
    | 'black'
    | 'gray'
    | 'red'
    | 'green'
    | 'blue'
    | 'yellow'
    | 'purple'
    | 'teal'
    | 'rose'
    | 'curry-yellow'
    | 'mid-gray'
